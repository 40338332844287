<template>
    <div class="container text-center py-2">
      <div class="row justify-content-center py-2">
        <div class="col-md-2 my-1">
          <router-link to="/">Home</router-link>
          </div>
        <!--
        <div class="col-md-2 my-1">
          <router-link to="/about">About</router-link>
        </div>
        -->
        <div class="col-md-2 my-1">
          <router-link to="/projects">Projects</router-link>
        </div>
        <div class="col-md-2 my-1">
          <router-link to="/blog">Blog</router-link>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "NavigationBar"
}
</script>

<style scoped>
 a{
   color: #65737e;
   text-decoration: none;
   font-weight: 400;
   font-size: 20px;
   letter-spacing: 7px;
   text-transform: uppercase;
   text-align: center;
 }

 a.router-link-exact-active{
   color: white;
 }

 a:hover {
   color: #800080;
 }
</style>