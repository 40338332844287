<template>
<div class="container">
  <div class="row justify-content-center my-4 mx-1">
    <div class="col-md-7" style="background-color: #a7adba; border-radius: 10px">
      <div class="row justify-content-start">
        <div class="col" style="background-color: #343d46; position: relative; border-radius: 10px; transform: translate(-10px, -10px);">
          <router-link style="text-decoration: none" :to="{ name: 'project', params: { project: cosmicID }}">
            <h5 style="padding-top:10px">{{title}}<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="3 1 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg></h5>
          </router-link>
          <p>{{description}}</p>
        </div>
        <div class="col-md-4">
          <div class="row justify-content-start py-1">
            <div class="col">
              <div class="row" v-for="(tech,index) in techArr"
                   :key="index">
                <span class="tech-styling">{{tech}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ProjectCard",
  props: ['title', 'description', 'techArr', 'cosmicID'],
}
</script>

<style scoped>
.tech-styling {
  color: black;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

a{
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
}

a:hover {
  color: #800080;
}

</style>