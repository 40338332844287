<template>
  <div class="container">
    <div class="row justify-content-center mb-3 mt-2 mx-1" style="background-color: #343d46; border-radius: 10px; padding-top: 10px; padding-bottom: 10px">
      <div class="col-md-3 d-flex align-items-center justify-content-center">
        <img class="custom-thumbnail rounded-circle img-fluid " :src="image" alt="thumbnail"/>
      </div>
      <div class="col">
          <router-link style="text-decoration: none; padding-top: 10px" :to="{ name: 'blogPost', params: { post: blogPostID }}">
            <h5 class="float-start blog-link">{{title}}</h5>
          </router-link>
          <span class="float-end py-1"><span style="font-size: 15px; transform: translateY(2px); margin-right: 5px" class="material-icons-outlined">calendar_today</span>{{formatDate()}}</span>
          <p class="float-start">{{cleanDescription()}}</p>
        <div class="float-end text-end">
          <router-link style="text-decoration: none; padding-top: 10px" :to="{ name: 'blogPost', params: { post: blogPostID }}">
            <span class="blog-link">Read More <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="7 1 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg></span>
          </router-link>
        </div>
      </div>
     </div>
  </div>
</template>

<script>
import {format, fromUnixTime} from 'date-fns';
export default {
  name: "BlogCard",
  props: ['title', 'description', 'date', 'blogPostID', 'image'],
  methods: {
    formatDate() {
      return format(fromUnixTime(this.date.seconds), 'MMMM d, yyyy');
    },
    cleanDescription() {
      return this.description.length>300 ? this.description.substring(0,299)+'...' : this.description
    }
  }
}
</script>

<style scoped>

.blog-link {
  color: white;
}

.blog-link:hover {
  color: #800080;
}

.custom-thumbnail {
  max-height: 150px;
  max-width: 150px;
}

</style>