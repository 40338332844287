<template>
  <footer class="text-center" style="margin-top: 4rem">
    <div class="container p-4">
      <section class="mb-4">
        <a href="https://twitter.com/StanleyMohr"><img class="social-button" alt="Twitter" :src="require('../assets/twitter_logo_white.png')"></a>
        <a href="https://www.linkedin.com/in/stanley-mohr/"><img class="social-button" alt="LinkedIn" :src="require('../assets/linkedin_logo_white.png')"></a>
        <a href="https://github.com/SMCSBeavers"><img class="social-button" alt="GitHub" :src="require('../assets/github_logo_white.png')"></a>
      </section>
    </div>
    <!-- Copyright -->
    <div class="text-center p-2">
      <span class="cr-styling">© {{getDateForCopyright}} Copyright </span>
      <a class="text-white" href="https://stanleymohr.com/">stanleymohr.com</a>
    </div>
    <!-- Copyright -->
    <div class="text-center pb-1">
      <a class="text-white" style="font-size: 10px" href="https://vuejs.org/">Built with Vue.js <img class="img-fluid" style="height: 20px" alt="vueLogo" :src="require('../assets/logo.png')"/> </a>
    </div>
  </footer>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: "Footer",
  computed: {
    getDateForCopyright() {
      return format(new Date(), 'yyyy');
    }
  }
}
</script>

<style scoped>
.cr-styling {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.social-button {
  height: 25px;
  margin: 5px;
}
</style>