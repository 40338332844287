<template>
  <div class="container">
    <PageTitle :title="blogTitle"/>
    <div class="row justify-content-center">
      <div class="col-md-8 my-2 py-2" style="background-color: #343d4675; border-radius: 10px;">
        <div id="content"/>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import DOMPurify from 'dompurify';
import {bucketConfig} from "@/cosmicjs/bucketconfig";

const Cosmic = require('cosmicjs')
const api = Cosmic()
export const bucket = api.bucket(bucketConfig)
export default {
  name: "BlogPostView",
  components: {PageTitle},
  data() {
    return {
      blogTitle: "Loading...",
      blogObj: null,
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData() {
      bucket.getObject({
        id: this.$route.params.post,
        props: 'slug,title,content'
      }).then(async data => {
        this.blogObj = await data.object
        this.blogTitle = this.blogObj.title
        document.getElementById('content').innerHTML = await DOMPurify.sanitize(data.object.content);
      })
    },
  },
}
</script>

<style scoped>

</style>