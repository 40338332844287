<template>
  <div class="container">
    <HomeHeader/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHeader from '@/components/HomeHeader.vue'

export default {
  name: 'HomeView',
  components: {
    HomeHeader
  },
}
</script>

<style scoped>
body {
  background-color: #2c3e50;
}
</style>
