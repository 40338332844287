import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import AboutView from '../views/AboutView.vue'
import BlogView from '../views/BlogView.vue'
import ProjectOverview from "@/views/ProjectOverview";
import ProjectView from "@/views/ProjectView";
import BlogPostView from "@/views/BlogPostView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  /*
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
     */
  {
    path: '/projects',
    name: 'projects',
    component: ProjectOverview
  },
  {
    path: '/projects/:project',
    name: 'project',
    component: ProjectView,
    props : true
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView
  },
  {
    path: '/blog/:post',
    name: 'blogPost',
    component: BlogPostView,
    props : true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
