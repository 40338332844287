<template>
  <div class="container" style="margin-top: 2rem">
    <div class="row justify-content-center text-center">
      <h3 class="title-1">{{ title }}</h3>
      <!--
      <div class="animated-blob">
        <svg id="visual" viewBox="0 0 900 600" width="600" height="400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><g transform="translate(462.26190393570175 290.96094598028503)"><path d="M142.5 -127.3C182.6 -102.3 211.8 -51.1 216.5 4.7C221.2 60.6 201.5 121.2 161.3 148.2C121.2 175.2 60.6 168.6 9.8 158.8C-41 149 -82 136 -132 109C-182 82 -241 41 -241.7 -0.7C-242.4 -42.4 -184.9 -84.9 -134.9 -109.9C-84.9 -134.9 -42.4 -142.4 4.4 -146.8C51.1 -151.2 102.3 -152.3 142.5 -127.3" fill="#800080"></path></g></svg>
      </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: ['title'],
}
</script>

<style scoped>
.title-1 {
  position: relative;
  animation: title-1-animation 0.25s;
  animation-iteration-count: 1;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 1px;
  animation-timing-function: ease-out;
}

@keyframes title-1-animation {
  0% {opacity: 0}
  100% {opacity: 100}
}

</style>