<template>
<div class="container">
  <div class="row d-flex align-items-center justify-content-center" style="height: 40rem;">
    <div class="col-md-6">
      <span class="subtitle-1">Software Developer</span>
      <div class="background-div">
        <svg id="visual" viewBox="0 0 900 600" width="900" height="600" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><g transform="translate(440.8878455251824 305.3209738897465)"><path d="M146.6 -152.7C176.2 -116.9 177.1 -58.5 183.2 6.1C189.4 70.7 200.8 141.4 171.1 169.8C141.4 198.1 70.7 184 17.7 166.4C-35.4 148.7 -70.7 127.4 -103.7 99C-136.7 70.7 -167.4 35.4 -171.8 -4.5C-176.3 -44.3 -154.6 -88.6 -121.6 -124.5C-88.6 -160.3 -44.3 -187.6 7.1 -194.7C58.5 -201.8 116.9 -188.6 146.6 -152.7" fill="#800080"></path></g></svg>
      </div>
      <h2><span class="name-1">Stanley </span><span class="name-2">Mohr</span></h2>
      <p class="description-body" style="color: white">Recent Computer Science graduate offering a strong foundation in software engineering with diverse background in science, finance, and healthcare. Experienced in Full Stack Development and Mobile Development with focus on developing front-end user experiences accelerated by numerous back-end services and databases.</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "HomeHeader"
}
</script>

<style scoped>
.background-div {
  position: fixed;
  z-index: -1;
  animation: background-1-div 10s infinite;
  animation-timing-function: linear;
}

@keyframes background-1-div {
  0% {transform: translate(-50px,-200px) rotate(0deg)}
  25% {transform: translate(-130px,-180px) rotate(90deg)}
  50% {transform: translate(-130px,-170px) rotate(180deg)}
  75% {transform: translate(-130px,-180px) rotate(270deg)}
  100% {transform: translate(-50px,-200px) rotate(360deg)}
}

.subtitle-1 {
  position: relative;
  animation: subtitle-1-animation 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 7px;
  text-transform: uppercase;
}

@keyframes subtitle-1-animation {
  0% {bottom: 100vw}
  100% { bottom: 0}
}

.name-1 {
  position: relative;
  animation: name-1-animation 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  font-weight: 700;
  font-size: 60px;
  letter-spacing: 1px;
}

@keyframes name-1-animation {
  0% {right: 100vw}
  100% { right: 0}
}

.name-2 {
  position: relative;
  animation: name-2-animation 0.75s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  font-weight: 700;
  font-size: 60px;
  letter-spacing: 1px;
}

@keyframes name-2-animation {
  0% {left: 100vw}
  100% { left: 0}
}

.description-body {
  position: relative;
  animation: text-body-animation 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  font-size: 15px;
  color:white;
  letter-spacing: 1px;
  width: 70%
}

@keyframes text-body-animation {
  0% {top: 100vw}
  100% { top: 0}
}
</style>