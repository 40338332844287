import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCtFa8LiSstOy73ImOv-Y71gd8wtUhOdG4",
    authDomain: "personal-website-d48b4.firebaseapp.com",
    projectId: "personal-website-d48b4",
    storageBucket: "personal-website-d48b4.appspot.com",
    messagingSenderId: "1057971963684",
    appId: "1:1057971963684:web:6a34981997aaf81e6595b1",
    measurementId: "G-2ETJZWVQY5"
};
export const firebaseApp = initializeApp(firebaseConfig);