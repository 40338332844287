<template>
  <PageTitle :title="pageTitle"/>
  <ProjectCard v-for="project in projectArr" :key="project.id" :tech-arr="project.techArr" :title="project.projectTitle" :description="project.description" :cosmicID="project.cosmicID"></ProjectCard>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import ProjectCard from "@/components/ProjectCard";
import { firebaseApp } from '@/firebase/index.js';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

export default {
  name: "ProjectOverview",
  components: {
    ProjectCard,
    PageTitle
  },
  async created() {
    const db = getFirestore(firebaseApp);
    const projectsCol = collection(db, 'projects');
    const projectSnap = await getDocs(projectsCol);
    this.projectArr = projectSnap.docs.map(doc => doc.data());
  },
  data() {
    return {
      pageTitle: "Projects",
      projectArr: [],
    }
  },
}
</script>

<style scoped>


</style>