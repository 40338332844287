<template>
  <PageTitle :title="pageTitle"/>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8"
               v-for="post in blogpostArr"
               :key="post.id">
            <BlogCard
                :title="post.title"
                :description="post.description"
                :date="post.date"
                :blog-post-i-d="post.blogPostID"
                :image="post.image"
            ></BlogCard>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import BlogCard from "@/components/BlogCard";
import {collection, getDocs, getFirestore} from "firebase/firestore/lite";
import {firebaseApp} from "@/firebase";

export default {
  name: "BlogView",
  components: {BlogCard, PageTitle},
  data() {
    return {
      pageTitle: "Blog",
      blogpostArr: [],
    }
  },
  async created() {
    const db2 = getFirestore(firebaseApp);
    const blogsCol = collection(db2, 'blogs');
    const blogSnap = await getDocs(blogsCol);
    this.blogpostArr = blogSnap.docs.map(doc => doc.data());
  },
}
</script>

<style scoped>

</style>