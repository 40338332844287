<template>
  <NavigationBar/>
    <router-view/>
  <Footer/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');
#app {
  font-family: 'Open Sans', sans-serif;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<script>
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer.vue"
export default {
  components: {NavigationBar, Footer}
}
</script>